import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import { useIsAuthenticated } from 'react-auth-kit';
import { useSignOut } from 'react-auth-kit';
import Button from 'react-bootstrap/Button';

export default function Navbarr() {
  const isAuthenticated = useIsAuthenticated();
  const signOut = useSignOut();
  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="#home">
          Ordini in entrata{' '}
          <Spinner
            animation={isAuthenticated() ? 'grow' : ''}
            variant="danger"
          />
        </Navbar.Brand>
        <Button
          variant="link"
          onClick={() => {
            signOut();
          }}
        >
          {isAuthenticated() ? 'logout' : ''}
        </Button>
      </Container>
    </Navbar>
  );
}