import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page: 1,
};

export const PaginaSlicer = createSlice({
  name: 'pagina',
  initialState,
  reducers: {
    incrementa: (state) => {
      if (state > 3) {
        state.page = 1;
      }
      state.page += 1;
    },
    resetta: (state) => {
      state.page = 1;
    }
  },
});

// Action creators are generated for each case reducer function
export const { incrementa, resetta } = PaginaSlicer.actions;

export default PaginaSlicer.reducer;
