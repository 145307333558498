import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cliente: {
    nome: null,
    value: null,
  },
  spedizione: {
    nome: null,
    value: null,
  },
  prodotti: [],
  note: [],
  disabled: true,
};

export const ordineSlice = createSlice({
  name: 'ordine',
  initialState,
  reducers: {
    addProdotti: (state, action) => {
      state.prodotti = action.payload;
    },
    addspedizione: (state, action) => {
      state.spedizione.nome = action.payload.nome;
      state.spedizione.value = action.payload.value;
    },
    addcliente: (state, action) => {
      state.cliente.nome = action.payload.cliente;
      state.cliente.value = action.payload.value;
    },
    addnote: (state, action) => {
      state.note.push(action.payload);
    },
    disable: (state, action) => {
      state.disabled = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { addProdotti, addspedizione, addcliente, addnote, disable } =
  ordineSlice.actions;

export default ordineSlice.reducer;
