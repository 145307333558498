import { configureStore } from '@reduxjs/toolkit';
import ordineReducer from './features/ordineSlicer';
import paginaReducer from './features/PaginaSlicer';

export const store = configureStore({
  reducer: {
    ordine: ordineReducer,
    pagina: paginaReducer,
  },
});
