import React, { useState, useEffect } from "react";
import { useAuthHeader } from "react-auth-kit";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Ratio from "react-bootstrap/Ratio";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

function Home() {
  const [Ordini, setOrdini] = useState([]);
  const [traking, setTraking] = useState("");
  const [coriere, setcoriere] = useState("");
  const [ordineselezionatomodal, setordineselezionatomodal] = useState(0);
  const [validated, setValidated] = useState(false);
  const [trakingselezionato, settrakingselezionato] = useState("0");
  const authHeader = useAuthHeader();
  //gestione spinner
  const [spinner, setspinner] = useState(false);
  const disablespinner = () => setspinner(false);
  const activatespinner = () => setspinner(true);
  //gestione popup spedisci
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //gestione offcanvas
  const [showoffcanvas, setShowoffcanvas] = useState(false);
  const handleCloseoffcanvas = () => setShowoffcanvas(false);
  const toggleShowoffcanvas = () => setShowoffcanvas((s) => !s);
  //configurazione richieste api
  const config = {
    headers: { Authorization: `${authHeader()}` },
  };
  const configtrakingmore = {
    headers: {
      "Content-Type": "application/json",
      "Tracking-Api-Key": "45c44aae-57a2-4b25-b629-91c3e6a51928",
    },
  };

  //opzioni per orario card
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  //funzione che aggiorna ogni 10 secondi il monitor
  useEffect(() => {
    getOrdini();
    const interval = setInterval(() => getOrdini(), 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  //scarica gli ordini dal server
  function getOrdini() {
    axios
      .get(`https://ordini-bioservicesrl.herokuapp.com/ordinis`, config)
      .then((res) => {
        const data = res.data;
        setOrdini(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // aggiorna il traking su trackingmore
  function updatetrackingmore(traking, valore, coriere) {
    const datatrack = {
      tracking_number: `${traking}`,
      courier_code: `${coriere}`,
    };
    console.log("reqeyst", datatrack);
    axios
      .post(
        `https://middleware-traking.herokuapp.com/https://api.trackingmore.com/v3/trackings/create`,
        datatrack,
        configtrakingmore
      )
      .then((res) => {
        console.log(res);
        modificaStato(valore, ordineselezionatomodal);
        NotificationManager.success(
          "notifiche al cliente e sms attivati correttamente ",
          "Ottimo"
        );
      })
      .catch((err) => {
        console.log("ce stato un errore ", err);
        NotificationManager.error(
          "qualcosa è andato storto in tralkingmore",
          `${err.message}`
        );
      });

    axios
      .post(
        `https://middleware-traking.herokuapp.com/https://api.trackingmore.com/v3/trackings/realtime`,
        datatrack,
        configtrakingmore
      )
      .then((res) => {
        console.log("live", res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // aggiorna il traking su tutti i sistemi
  function spedisci(traking, id, valore) {
    axios
      .put(
        `https://ordini-bioservicesrl.herokuapp.com/ordinis/${id}`,
        { tracking: traking },
        config
      )
      .then((res) => {
        console.log(res);
        updatetrackingmore(traking, valore);
        handleClose();
        getOrdini();
        NotificationManager.success(
          "Traking ordine aggiornato correttamente",
          "Ottimo"
        );
      })
      .catch((err) => {
        console.log("ce stato un errore ", err);
        NotificationManager.error("qualcosa è andato storto", "errore");
      });
  }

  //modifica Stato dell o?dine sul server
  function modificaStato(stato, id) {
    axios
      .put(
        `https://ordini-bioservicesrl.herokuapp.com/ordinis/${id}`,
        { stato_ordine: stato },
        config
      )
      .then((res) => {
        console.log(res);
        getOrdini();
        NotificationManager.success(
          "Stato ordine aggiornato correttamente",
          "Ottimo"
        );
      })
      .catch((err) => {
        console.log("ce stato un errore ", err);
        NotificationManager.error(
          "qualcosa è andato storto nella modifica dello stato6",
          "errore"
        );
      });
  }

  //gestione colore card in baso allo stato dell ordine
  function colorecard(stato) {
    if (stato === "spedito") {
      return "colore-card-spedito text-center";
    }
    if (stato === "in_lavorazione") {
      return "colore-card-inlavorazione text-center";
    }
    if (stato === "ricevuto") {
      return "colore-card-ricevuto text-center";
    }
  }

  return (
    <Container className=' mt-3'>
      <Row>
        <Col>
          {Ordini.sort(function (a, b) {
            var keyA = new Date(a.created_at),
              keyB = new Date(b.created_at);
            // Compare the 2 dates
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          }).map((ordine) => (
            <Card className='mb-4'>
              <Card.Header className={colorecard(ordine.stato_ordine)}>
                {" "}
                <h4>Ordine #{ordine.id}</h4>
              </Card.Header>
              <Card.Body
                className={ordine.opzionale.urgente === true ? "bg-custom" : ""}
              >
                <Row>
                  <Col xs={7}>
                    <Row>
                      <Card.Title>
                        Venditore: {ordine.nome_venditore}
                      </Card.Title>
                    </Row>
                    <Row>
                      <Card.Title>
                        Ordinato da: {ordine.opzionale.cliente.nome}
                      </Card.Title>
                    </Row>
                    <Row>
                      <Card.Title>
                        Spedire a: {ordine.opzionale.spedizione.nome}
                      </Card.Title>
                    </Row>
                  </Col>
                  <Col>
                    <Card.Title>
                      Prodotti ordinati:
                      {ordine.opzionale.prodotti.map((x) => (
                        <p> {` ${x.quantità} X ${x.label}`} </p>
                      ))}
                    </Card.Title>
                    <Card.Title>
                      Note aggiuntive:<p>{`${ordine.opzionale.note}`}</p>
                    </Card.Title>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className='text-muted text-centered'>
                {ordine.tracking == null || ordine.tracking == "" ? (
                  ""
                ) : (
                  <Button
                    onClick={() => {
                      toggleShowoffcanvas();
                      settrakingselezionato(ordine.tracking);
                    }}
                    className='button-tracking2'
                  >
                    traccia
                  </Button>
                )}
                {ordine.tracking === null || ordine.tracking === "" ? (
                  <Button
                    className='button-traking'
                    onClick={() => {
                      setordineselezionatomodal(ordine.id);
                      handleShow();
                    }}
                  >
                    Spedisci
                  </Button>
                ) : (
                  <h4>Ordine Spedito</h4>
                )}

                {ordine.tracking === null || ordine.tracking === "" ? (
                  <Button
                    id='in_lavorazione'
                    className='button-warning'
                    onClick={(e) => {
                      modificaStato(e.target.id, ordine.id);
                    }}
                  >
                    Metti in lavorazione
                  </Button>
                ) : (
                  ""
                )}

                {` Ricevuto ${new Date(ordine.created_at).toLocaleDateString(
                  "it-IT",
                  options
                )}`}
              </Card.Footer>
            </Card>
          ))}
        </Col>
      </Row>
      <Modal
        show={show}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header>
          <Modal.Title id='contained-modal-title-vcenter'>
            Inserisci il Traking
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group
            className='mb-3'
            value={traking}
            onChange={(e) => setTraking(e.target.value.replace(/ /g, ""))}
          >
            <Form.Label>
              Inserisci il Traking del ordine #{ordineselezionatomodal}
            </Form.Label>
            <Form.Control />
            <Form.Label className='mt-2'>Quale coriere hai usato?</Form.Label>
            <Form>
              <Form.Check
                name='group1'
                type='radio'
                label='GLS'
                value='gls-italy'
                onChange={() => {
                  setcoriere("gls-italy");
                }}
              />
              <Form.Check
                onChange={() => {
                  setcoriere("bartolini");
                }}
                name='group1'
                type='radio'
                label='BRT'
                value='bartolini'
              />
            </Form>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            required
            id='spedito'
            onClick={(e) => {
            spedisci(
                    traking,
                    ordineselezionatomodal,
                    e.target.id,
                    coriere
                  );
            }}
          >
            Spedisci
          </Button>
          <Button variant='secondary' onClick={handleClose}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
      <Offcanvas show={showoffcanvas} onHide={handleCloseoffcanvas}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Traking</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {spinner ? (
            <div>loading</div>
          ) : (
            <iframe
              src={`https://t.trackingmore.com/bioservice/${trakingselezionato}`}
              width='100%'
              height='100%'
              onLoad={disablespinner}
            ></iframe>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <NotificationContainer />
    </Container>
  );
}

export default Home;

// gestione delle notifiche app
function createNotification(type) {
  return () => {
    switch (type) {
      case "info":
        NotificationManager.info("Info message");
        break;
      case "success":
        NotificationManager.success("Success message", "Title here");
        break;
      case "warning":
        NotificationManager.warning(
          "Warning message",
          "Close after 3000ms",
          3000
        );
        break;
      case "error":
        NotificationManager.error("Error message", "Click me!", 5000, () => {
          alert("callback");
        });
        break;
    }
  };
}
